import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/shared/Container"
import SEO from "../components/seo"

import { device } from "../utils/device"

const ContactPage = ({data}) => {
  const meishi = data.file.childImageSharp.fixed.src

  return (
    <Layout breadCrumb={{to: '/contact', text: `Contact`}}>
      <SEO title="Contact" />
      <Container>
        <Wrapper img={meishi}>
          <ContactBox>
            <Message>
              <Label color="white">
                Contact
              </Label>
              <p>業務に関するご依頼・ご相談、当サイトに対するご感想やメッセージはメールにてお気軽にお問い合わせください。<br />3営業日以内に返信させていただきます。</p>
            </Message>
            <MailBox>
              <Label>
                Mail
              </Label>
              <a href="mailto:yo@okysk.com">
                yo@okysk.com
              </a>
            </MailBox>
          </ContactBox>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export const query  = graphql`
  query {
    file(relativePath: {eq: "meishi.jpg"}) {
        childImageSharp{
            fixed(width: 1600) {
              src
            }
        }
    }
  }
`

const Label = styled.span`
  display: block;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${props => props.color || props.theme.colors.keyColor };
  font-size: 0.8em;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 192px);
  background-image: url(${props => props.img});
  background-position: center center;
  background-size: cover;
`

const ContactBox = styled.div`
  min-height: 50%;
  width: 70%;
  border: 1px solid ${props => props.theme.colors.keyColor};

  @media ${device.mobile} {
    width: 95%;
  }
`

const Message = styled.div`
  background-color: ${props => props.theme.colors.keyColor};
  padding: 64px;
  font-size: 14px;
  line-height: 1.6;
  color: ${props => props.theme.colors.whiteColor};

  @media ${device.mobile} {
    padding: 32px;
  }
`

const MailBox = styled.div`
  background-color: white;
  padding: 64px;
  font-size: 14px;
  line-height: 1.6;

  a {
    color: inherit;
  }

  @media ${device.mobile} {
    padding: 32px;
  }
`

export default ContactPage